<template>
  <panel title="Call Automation">
    <app-timeline>
      <!-- 12 INVOICES HAVE BEEN PAID -->
      <app-timeline-item v-for="(data, index) in datas" :key="data.id">
        <div
          class="
            d-flex
            flex-sm-row flex-column flex-wrap
            justify-content-between
            mb-1 mb-sm-0
          "
        >
          <h6 v-if="data.title">{{ data.title }}</h6>
        </div>
        <p v-html="data.detail" />
        <p class="mb-0" v-for="(image, i) in data.image_datas" :key="image.id">
          <b-img
            :src="`${url}/image/${image.name}`"
            class="mr-1"
            fluid
            alt="Responsive image"
          />
        </p>
      </app-timeline-item>
    </app-timeline>
  </panel>
</template>

<script>
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue"
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue"
export default {
  created() {
    this.fetchData()
  },
  data() {
    return {
      url: process.env.VUE_APP_API_BASEURL,
      datas: [],
    }
  },
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  methods: {
    async fetchData() {
      try {
        this.datas = await this.api.get(`/api/timelineData/CallAutomation`)
      } catch (error) {
        console.error(error)
        return ""
      }
    },
  },
}
</script>
